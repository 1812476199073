import styled, { css } from 'styled-components'

import { Button, breakpoints, ContentContainer, MediumHeader } from 'src/utils/styles/global-styles'

const cartSummaryItemStyles = css`
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  span:first-child {
    font-family: 'FilsonProBook';
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.08px;
    text-align: left;
    padding-bottom: 10px;
    @media (max-width: 350px) {
      font-size: 14px;
    }
  }
  span:nth-child(2) {
    font-family: 'FilsonPro';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.09px;
    font-weight: normal;
    text-align: left;
    @media (max-width: 350px) {
      font-size: 14px;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Table = styled.table`
  width: 92%;
  border-collapse: collapse;
  table-layout: fixed;
  @media (max-width: ${breakpoints.s}px) {
    thead th:nth-child(3) {
      width: 90px;
    }
    thead th:nth-child(2) {
      width: 80px;
    }
  }
  @media (max-width: 350px) {
    thead th:nth-child(3) {
      width: 70px;
    }
    thead th:nth-child(2) {
      width: 75px;
    }
  }
`

export const TableHeader = styled.th`
  text-align: ${(props) => props.textAlign || 'left'};
  font-weight: 300;
  font-family: 'FilsonProBook';
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.08px;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: 350px) {
    font-size: 14px;
  }
`

export const DesktopOnlyTableHeader = styled(TableHeader)`
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
export const MobileOnlyTableHeader = styled(TableHeader)`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: inline-block;
  }
`
export const Checkout = styled.section`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 30px;
  @media (max-width: ${breakpoints.m}px) {
    padding-right: 0px;
    padding-top: 16px;
  }
`

export const LineItemTotal = styled.p`
  ${cartSummaryItemStyles}
`

export const Excluding = styled.p`
  font-family: 'FilsonProBook';
  line-height: 26px;
  color: ${(props) => (props.golden ? props.theme.golden : props.theme.grayDark)};
  font-size: 15px;
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`
export const Offer = styled(Excluding)`
  margin: 0px;
  line-height: 140%;
  width: 400px;
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
    margin: auto;
    max-width: 100%;
  }
`
export const CheckoutButtons = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin: ${(props) => (props.marginTop ? '40px 0px' : '0px')};
  @media (max-width: ${breakpoints.m}px) {
    margin: ${(props) => (props.marginTop ? '40px 0px' : 'auto')};
  }
  @media (max-width: ${breakpoints.s}px) {
    flex-wrap: wrap;
    margin: ${(props) => (props.marginTop ? '20px auto' : 'auto')};
  }
`

export const CheckoutButton = styled(Button)`
  font-size: 16px;
  min-width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  font-family: 'FilsonPro';
  font-size: 18px;
  font-weight: normal;
  @media (max-width: ${breakpoints.m}px) {
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`

export const PayPalCheckoutButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 12.25px 22px;
  margin-right: 1rem;
  border-radius: 28px;
  > div {
    margin: auto;
  }
  &:hover {
    background-color: ${(props) => props.color};
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  }
`

export const CartSummary = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1.5px solid rgba(68, 68, 68, 0.1);
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 17px 0px 17px 0px;
  }
  > div {
    margin-right: 90px;
    width: 420px;
    display: flex;
    justify-content: space-between;
  }
`

export const Discount = styled.p`
  ${cartSummaryItemStyles}
`

export const Total = styled.div`
  ${cartSummaryItemStyles}
  span:nth-child(2) {
    color: ${(props) => props.theme.coral};
  }
`
export const SpinnerCtn = styled.div`
  min-height: 182px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const QuantityHeader = styled(TableHeader)`
  @media (max-width: ${breakpoints.s}px) {
    text-indent: -9999px;
    position: relative;
    :after {
      text-indent: 0;
      visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      content: 'Qty';
    }
  }
`
export const TableRow = styled.tr`
  border-bottom: 1.5px solid rgba(68, 68, 68, 0.1);
`
export const TD = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  p {
    font-family: 'FilsonProBook';
    font-size: 18px;
  }
  @media (max-width: ${breakpoints.m}px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 350px) {
    p {
      font-size: 14px;
    }
  }
`
export const DisplayOnDesktop = styled.div`
  display: block;
  margin-top: 16px;
  @media (max-width: ${breakpoints.m}px) {
    display: none;
  }
`
export const DisplayOnMobile = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}px) {
    display: block;
    width: 100%;
  }
  p > span:not(:first-child),
  div > span:not(:first-child) {
    font-family: 'FilsonProBook';
    font-size: 16px;
    @media (max-width: 350px) {
      font-size: 14px;
    }
  }
  p {
    margin: 0px;
  }
`
export const Amount = styled.p`
  font-family: 'FilsonProBook';
  font-size: 18px;
  letter-spacing: 0.09px;
  color: ${(props) => props.color};
`
export const HideOnSmallScreen = styled.span`
  @media (max-width: 460px) {
    display: none;
  }
`
export const Container = styled.div`
  min-height: 50vh;
`
export const HiddenOnDesktop = styled.div`
  display: none;
  padding: 0px;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    display: inline-block;
    display: contents;
  }
`
export const CheckoutContainer = styled(ContentContainer)`
  padding: 2rem 0px 4rem 0px;
  margin: 0px auto 0px;
`
export const Header = styled(MediumHeader)`
  padding: 10px 0px;
  text-align: left;
  width: 1200px;
  max-width: 90vw;
  margin: auto;
`
export const ProductListContainer = styled(ContentContainer)`
  margin: 1rem auto 4rem;
`
export const DiscountText = styled.span`
  color: ${(props) => props.theme.blueDark};
`
export const Subtotal = styled.p`
  text-decoration: ${(props) => (props.strikethrough ? 'line-through' : 'none')};
`
export const Error = styled.p`
  color: ${(props) => props.theme.coral};
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`
export const ALink = styled.a`
  color: ${(props) => props.theme.coral};
  text-decoration: none;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`
export const DiscountDisclosure = styled.p`
  text-align: right;
  font-family: 'FilsonPro';
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  span {
    text-decoration: underline;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
    margin-top: 8px;
    text-align: center;
  }
`
